// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { odbcAddRoute } from 'routes/odbcAddRoute';
import { odbcEditRoute } from 'routes/odbcEditRoute';
import apolloClient from '../apolloClient';

import ODBC_DOMAIN_LIST from 'queries/OdbcDomainList.graphql';
import ODBC_DOMAIN_PAGE from 'queries/OdbcDomainPage.graphql';

export const odbcDomainRoute = {
    path: 'odbc',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/OdbcPage/OdbcDomainPage" */
        'components/pages/OdbcPage/OdbcDomainPage'
    ),
    loader: async ({ params }) => {
        const input = readGraphQlListInputFromStorage({ key: `OdbcDomainPage${params.domainId}` });
        const id = btoa(`Domain:${params.domainId}`);

        await Promise.all([
            apolloClient.query({
                query: ODBC_DOMAIN_PAGE,
                variables: { id },
            }),
            apolloClient.query({
                query: ODBC_DOMAIN_LIST,
                variables: { id, input },
            }),
        ]);
    },
    handle: {
        helpContext: 'odbc_connection_config',
        crumb: ({ matchesContext }) => {
            if (!matchesContext.domainRouteData) {
                return null;
            }
            const { domain } = matchesContext.domainRouteData;

            const locale = Locale.getSection('components.pages.Odbc');
            return {
                title: locale.lmsg('domainTitle', { domainName: `<b>${domain.name}</b>` }),
            };
        },
        key: ({ params }) => params.domainId,
    },
    children: [
        odbcAddRoute,
        odbcEditRoute,
    ],
};
