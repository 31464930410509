// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { PopupForm } from './popup-form';
import { getComponent } from './component';
import prepareUrl from './prepareUrl';
import render from './render';

export class AjaxPopupForm extends PopupForm {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._url = this._getConfigParam('url');
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.querySelector('.popup-content-area').innerHTML = `<div class="ajax-loading">${this.lmsg('waitMsg')}</div>`;
        new Ajax.Request(prepareUrl(this._url), {
            method: 'get',
            onSuccess: this._onSuccess.bind(this),
        });
    }

    _onSuccess(transport) {
        render(this._componentElement.querySelector('.popup-content-area'), transport.responseText, 'inner');
        setTimeout(() => {
            this._componentElement.querySelector('form').setAttribute('action', prepareUrl(this._url));
            const cancelBtn = getComponent('btn-cancel');
            cancelBtn.removeEventObserver('click', cancelBtn._handler);
            cancelBtn._handler = () => {
                this.hide();
            };
            cancelBtn.addEventObserver('click', cancelBtn._handler);
        }, 10);
    }
}
