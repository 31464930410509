// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { List } from './list';
import createElement from './createElement';

/**
 * The extension of the list widget: fits 100% height of screen.
 */
export class ScrollableList extends List {
    listView() {
        const view = super.listView();

        view.attrs.class += ' scrollable-list';
        view.children[0].attrs.class = 'scrollable-list-table';
        view.children = [
            createElement('table.fixed-table-head[width="100%"][cellspacing="0"]',
                this.listHeadersView(),
            ),
            createElement('.scrollable-list-wrapper', view.children),
        ];

        return view;
    }

    checkEmptyList() {
        super.checkEmptyList();

        const isEmpty = this.isListEmpty();
        this._componentElement.querySelectorAll(`.${this._listCls}`).forEach(el => {
            el.style.display = '';
        });
        this._componentElement.querySelectorAll('.scrollable-list-wrapper').forEach(el => {
            el.style.display = isEmpty ? 'none' : '';
        });
    }

    /**
     * @param {String} place
     * @param {Number} dataLen
     */
    redraw(place, dataLen) {
        let bottom = true;
        let scrollTop;
        let tableHeight = 0;
        const wrapper = document.getElementById(this._id).querySelector('.scrollable-list-wrapper');

        if (wrapper) {
            bottom = (wrapper.offsetHeight + wrapper.scrollTop >= wrapper.scrollHeight);
            // eslint-disable-next-line prefer-destructuring
            scrollTop = wrapper.scrollTop;
            tableHeight = Element.getHeight(document.getElementById(this._tableId));
        }

        super.redraw(place, dataLen);
        const table = document.getElementById(this._tableId);
        const headerTable = table.closest(`.${this._listCls}`).querySelector('.fixed-table-head');

        table.style.marginTop = `${-Element.getHeight(headerTable)}px`;
        setTimeout(() => {
            this._resizeList();
            const wrapper = table.closest('.scrollable-list-wrapper');
            if (bottom) {
                this.scrollToBottom();
            } else if ('before' === place) {
                wrapper.scrollTop += Element.getHeight(table) - tableHeight;
            } else if ('after' === place) {
                // empty
            } else {
                wrapper.scrollTop = scrollTop;
            }
        }, 0);
    }

    updateFilter(force) {
        super.updateFilter(force);
        this._resizeList();
    }

    scrollToBottom() {
        const table = document.getElementById(this._tableId);
        const wrapper = table.closest('.scrollable-list-wrapper');
        const rows = [...wrapper.querySelectorAll('tr')].filter(el => el.style.display !== 'none');
        wrapper.scrollTop = rows[rows.length - 1].offsetTop;
    }

    _resizeList = () => {
        const minHeight = 200;
        const table = document.getElementById(this._tableId);
        const wrapper = table.closest('.scrollable-list-wrapper');

        const headerTable = table.closest(`.${this._listCls}`).querySelector('.fixed-table-head');
        const visibleHeader = headerTable.querySelector('thead');
        if (visibleHeader) {
            const isEmpty = this.isListEmpty();
            visibleHeader.querySelectorAll('tr').forEach(function (tr) {
                if (!tr.classList.contains('list-search-filter')) {
                    tr.style.display = isEmpty ? 'none' : '';
                }
            });
        }

        let blockHeight = Element.getHeight(document.body) - Element.cumulativeOffset(wrapper).top;
        for (let up = wrapper; up instanceof Element; up = up.parentNode) {
            blockHeight = blockHeight
                - (parseInt(Element.getStyle(up, 'borderBottomWidth')) || 0)
                - (parseInt(Element.getStyle(up, 'marginBottom')) || 0)
                - (parseInt(Element.getStyle(up, 'paddingBottom')) || 0);
            for (let next = up.nextElementSibling; next instanceof Element; next = next.nextElementSibling) {
                if ('none' !== Element.getStyle(next, 'display') && 'fixed' !== Element.getStyle(next, 'position') && 'absolute' !== Element.getStyle(next, 'position')) {
                    blockHeight = blockHeight - Element.getHeight(next);
                }
            }
        }

        if (blockHeight < minHeight) {
            blockHeight = minHeight;
        }

        if (blockHeight > Element.getHeight(table) + parseInt(Element.getStyle(table, 'marginTop'))) {
            wrapper.style.height = null;
        } else {
            wrapper.style.height = `${blockHeight}px`;
        }

        if (!visibleHeader || ![...table.querySelectorAll('tr.odd,tr.even')].filter(el => el.style.display !== 'none').length) {
            return;
        }

        const invisibleHeader = table.querySelector('thead');
        invisibleHeader.querySelectorAll('th').forEach((th, index) => {
            visibleHeader.querySelectorAll('th')[index].style.width = `${Element.getWidth(th)}px`;
        });
        const headers = visibleHeader.querySelectorAll('th');
        const lastHeader = headers[headers.length - 1];
        lastHeader.style.width = `${(Element.getWidth(headerTable) - Element.getWidth(invisibleHeader.closest('table'))) + parseInt(Element.getStyle(lastHeader, 'width'))}px`;
    };

    _addResponsiveHtml() {
        // Don't need responsive UI in case of logs list
    }

    _addEvents() {
        super._addEvents();
        window.addEventListener('resize', this._resizeList, false);
    }

    remove() {
        window.removeEventListener('resize', this._resizeList, false);
        super.remove();
    }
}
