// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import apolloClient from 'apolloClient';

import PROTECTED_DIRECTORY_EDIT_PAGE from 'queries/ProtectedDirectoryEditPage.graphql';

export const protectedDirectoryEditRoute = {
    path: ':protectedDirectoryId/edit',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryEditPage" */
        'components/pages/ProtectedDirectoryEditPage'
    ),
    loader: async ({ params }) => {
        const { domainId, protectedDirectoryId } = params;
        const { data: { domain: { hosting }, protectedDirectory } } = await apolloClient.query({
            query: PROTECTED_DIRECTORY_EDIT_PAGE,
            variables: {
                id: btoa(`ProtectedDirectory:${protectedDirectoryId}`),
                domainId: btoa(`Domain:${domainId}`),
            },
        });

        return {
            protectedDirectoryId,
            protectedDirectory,
            isProtectedDirectoryLocationSupported: hosting?.isProtectedDirectoryLocationSupported || false,
        };
    },
};
