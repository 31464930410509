// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { StatusMessage } from './status-message';
import createComponent from './createComponent';

export default (type, message, params = {}) => {
    try {
        createComponent({
            componentType: params.componentType || StatusMessage,
            renderTo: 'main',
            renderMode: 'top',
            type,
            message,
            ...params,
        });
    } catch {
        // eslint-disable-next-line no-alert
        alert(`${type} | ${message}`);
    }
};
