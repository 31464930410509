// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { NOTIFICATION_CATEGORIES } from './PropTypes';

export const isNotificationCritical = notification => [NOTIFICATION_CATEGORIES.ALERT, NOTIFICATION_CATEGORIES.WARNING].includes(notification.category);

export const isAllowedFeedbackOrPromotionNotification = notification => (
    notification.isPromotion
    && [NOTIFICATION_CATEGORIES.PROMOTION, NOTIFICATION_CATEGORIES.FEEDBACK].includes(notification.category)
);
