// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Locale } from 'jsw';
import pageData from 'helpers/pageData';
import withPageLayout from './withPageLayout';

const withSimplePageLayout = (Component, extraProps) => withPageLayout(Component, props => {
    const { showFrames, pageHeader, pageSidebar } = pageData();
    const { breadcrumbs, helpContext } = typeof extraProps === 'function' ? extraProps(props) : extraProps;
    const pageTitle = breadcrumbs[breadcrumbs.length - 1].title;

    return {
        ...props,
        showFrames,
        pageHeader: {
            ...pageHeader,
            helpMenu: {
                ...pageHeader.helpMenu,
                helpContext,
            },
        },
        pageSidebar,
        pageContentHeader: {
            pageTitle,
            breadcrumbs: [pageSidebar.isPowerUserPanel ? null : {
                title: Locale.getSection('components.pages.home').lmsg('title'),
                href: '/admin/home',
            }, ...breadcrumbs].filter(Boolean),
        },
        pageTitle,
    };
});

export default withSimplePageLayout;
