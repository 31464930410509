// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';

import HOTLINK_PROTECTION_PAGE from 'queries/HotlinkProtectionPage.graphql';

export const hotlinkProtectionDomainRoute = {
    path: 'hotlink-protection',
    componentLoader: () => import(/* webpackChunkName: "components/pages/HotlinkProtectionPage" */'components/pages/HotlinkProtectionPage'),
    loader: async ({ params }) => {
        const { data } = await apolloClient.query({
            query: HOTLINK_PROTECTION_PAGE,
            variables: { id: btoa(`Domain:${params.domainId}`) },
        });
        return data;
    },
    handle: {
        helpContext: 'client.domain.hosting.hotlink-protection',
        crumb: ({ matchesContext }) => {
            const { domain } = matchesContext.domainRouteData;

            const locale = Locale.getSection('components.pages.HotlinkProtection');
            return { title: locale.lmsg('title', { domainName: `<b>${domain.name}</b>` }) };
        },
        key: ({ params }) => params.domainId,
    },
};
