// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Button } from './button';

export class SmallButton extends Button {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._cls = this._getConfigParam('cls', `s-btn${!this._title ? ' btn-icon-only' : ''}`);
    }
}

// TODO EXTDOCKER-50: Cannot open docker extension: Cannot read property 'push' of undefined
SmallButton.subclasses = [];
