// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import classNames from 'classnames';
import { Alert, Link } from '@plesk/ui-library';
import { Locale } from 'jsw';
import PropTypes from 'prop-types';

const Translate = Locale.getTranslate('components.forms.login');

const SuggestResolvableHostname = ({ baseClassName, className, link }) => {
    const match = link.match(/^https?:\/\/[^\/?#]*/);
    const host = match[0];

    return (
        <Alert
            intent="info"
            className={classNames(baseClassName, className)}
        >
            <Translate
                content="avoidSSLWarningsMsg"
                params={{
                    link: <Link href={link}>{host}</Link>,
                }}
            />
        </Alert>
    );
};

SuggestResolvableHostname.propTypes = {
    className: PropTypes.string,
    baseClassName: PropTypes.string,
    link: PropTypes.string.isRequired,
};

SuggestResolvableHostname.defaultProps = {
    className: undefined,
    baseClassName: 'login-ssl-warnings',
};

export default SuggestResolvableHostname;
