// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { isNotificationCritical } from './utils';

export default (notifications, filter) => notifications.filter(notification => (
    (!filter.isUnRead || !notification.isRead) &&
    (!filter.isFavorite || notification.isFavorite) &&
    (!filter.isCritical || isNotificationCritical(notification)) &&
    (
        !filter.search ||
        (notification.subject && notification.subject.toLowerCase().indexOf(filter.search.toLowerCase()) >= 0) ||
        (notification.context && notification.context.toLowerCase().indexOf(filter.search.toLowerCase()) >= 0)
    )
));
