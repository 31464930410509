// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import apolloClient from '../apolloClient';

import HOSTING_SETTINGS_PAGE from 'queries/HostingSettingsPage.graphql';

export const useGetDomainOverviewRoutePath = () => {
    const { pathname } = useLocation();

    return useCallback((id, type, path) => (
        pathname.includes('overview')
            ? `/smb/web/overview/id/${id}/type/${type}/${path}`
            : `/smb/web/view/${id}/${path}`
    ), [pathname]);
};

export default [
    {
        path: 'hosting-settings',
        componentLoader: () => import(/* webpackChunkName: "components/pages/HostingSettingsPage" */'components/pages/HostingSettingsPage'),
        loader: async ({ params }) => {
            await apolloClient.query({
                query: HOSTING_SETTINGS_PAGE,
                variables: { id: btoa(`Domain:${params.id}`) },
            });
        },
    },
    {
        path: 'connection-info',
        componentLoader: () => import(/* webpackChunkName: "components/pages/ConnectionInfo" */'components/pages/ConnectionInfo'),
    },
];
