// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { ListActions, ListAction } from '@plesk/ui-library';
import { Locale } from 'jsw';
import { NotificationPropType } from './lib/PropTypes';

const Translate = Locale.getTranslate('components.notification-center');

const NotificationActions = ({ notification: { id, isFavorite, code }, onUpdateNotifications, onRemoveNotification }) => (
    <ListActions>
        <ListAction
            data-action={isFavorite ? 'NOTIFICATION_UNFAVORITE' : 'NOTIFICATION_FAVORITE'}
            data-type={code}
            icon={isFavorite ? 'star-filled' : 'star'}
            primary
            onClick={() => onUpdateNotifications([id], {
                isFavorite: !isFavorite,
            })}
        >
            <Translate content={isFavorite ? 'unfavoriteNotification' : 'favoriteNotification'} />
        </ListAction>
        <ListAction
            data-action="NOTIFICATION_DELETE"
            data-type={code}
            icon="recycle"
            primary
            onClick={() => onRemoveNotification(id)}
        >

            <Translate content="deleteNotification" />
        </ListAction>
    </ListActions>
);

NotificationActions.propTypes = {
    notification: NotificationPropType.isRequired,
    onUpdateNotifications: PropTypes.func.isRequired,
    onRemoveNotification: PropTypes.func.isRequired,
};

export default NotificationActions;
