// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { protectedDirectoryAddRoute } from 'routes/protectedDirectoryAddRoute';
import { protectedDirectoryEditRoute } from 'routes/protectedDirectoryEditRoute';
import apolloClient from '../apolloClient';

import PROTECTED_DIRECTORY_LIST from 'queries/ProtectedDirectoryList.graphql';

export const protectedDirectoryListRoute = {
    path: '',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryListPage" */
        'components/pages/ProtectedDirectoryListPage'
    ),
    loader: async ({ params }) => {
        const { data: listData } = await apolloClient.query({
            query: PROTECTED_DIRECTORY_LIST,
            variables: {
                id: btoa(`Domain:${params.domainId}`),
                input: readGraphQlListInputFromStorage({ key: `ProtectedDirectoryListPage${params.domainId}` }),
            },
        });

        if (!listData.domain.hosting) {
            throw new Response('Not Found', { status: 404 });
        }
    },
    handle: {
        helpContext: 'client.domain.hosting.protected-directories',
        pageTitle: ({ matchesContext }) => {
            if (!matchesContext.domainRouteData) {
                return null;
            }
            const { domain } = matchesContext.domainRouteData;

            const locale = Locale.getSection('components.pages.ProtectedDirectoryList');
            return locale.lmsg('title', { domainName: `<b>${domain.name}</b>` });
        },
        key: ({ params }) => params.domainId,
    },
    children: [
        protectedDirectoryAddRoute,
        protectedDirectoryEditRoute,
    ],
};
