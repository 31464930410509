// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

const entries = {
    'app/editlist': () => import(/* webpackChunkName: "app/editlist" */'./editlist'),
    'app/rating': () => import(/* webpackChunkName: "app/rating" */'./rating'),
    'app/aha': () => import(/* webpackChunkName: "app/aha" */'./aha'),
    'app/eula': () => import(/* webpackChunkName: "app/eula" */'./eula'),
    'app/app-image/gallery': () => import(/* webpackChunkName: "app/app-image/gallery" */'./app-image/gallery'),
    'app/backup/list': () => import(/* webpackChunkName: "app/backup/list" */'./backup/list'),
    'app/backup/restore': () => import(/* webpackChunkName: "app/backup/restore" */'./backup/restore'),
    'app/backup/upload-files': () => import(/* webpackChunkName: "app/backup/upload-files" */'./backup/upload-files'),
    'app/backup/web-content-dynamic': () => import(/* webpackChunkName: "app/backup/web-content-dynamic" */'./backup/web-content-dynamic'),
    'app/database/assign-to-subscription': () => import(/* webpackChunkName: "app/database/assign-to-subscription" */'views/smb/database/list/AssignToSubscription'),
    'app/dns-helper': () => import(/* webpackChunkName: "app/dns-helper" */'./dns-helper'),
    'app/dns-helper/dns-settings': () => import(/* webpackChunkName: "app/dns-helper/dns-settings" */'./dns-helper/dns-settings'),
    'app/email-address/aliases-dynamic': () => import(/* webpackChunkName: "app/email-address/aliases-dynamic" */'./email-address/aliases-dynamic'),
    'app/email-address/form-ajax': () => import(/* webpackChunkName: "app/email-address/form-ajax" */'./email-address/form-ajax'),
    'app/email-address/postbox': () => import(/* webpackChunkName: "app/email-address/postbox" */'./email-address/postbox'),
    'app/email-address/responder-attachments-dynamic': () => import(
    /* webpackChunkName: "app/email-address/responder-attachments-dynamic" */
        './email-address/responder-attachments-dynamic'
    ),
    'app/file-manager/explorer': () => import(/* webpackChunkName: "app/file-manager/explorer" */'./file-manager/explorer'),
    'app/file-manager/file-name-popup-form': () => import(/* webpackChunkName: "app/file-manager/file-name-popup-form" */'./file-manager/file-name-popup-form'),
    'app/mail-settings/confirmation-remove-popup-form': () => import(
        /* webpackChunkName: "app/mail-settings/confirmation-remove-popup-form" */
        './mail-settings/confirmation-remove-popup-form'
    ),
    'app/hosting-settings/conflicts-checker': () => import(/* webpackChunkName: "app/hosting-settings/conflicts-checker" */'./hosting-settings/conflicts-checker'),
    'app/log-browser/browser': () => import(/* webpackChunkName: "app/log-browser/browser" */'./log-browser/browser'),
    'app/log-browser/files': () => import(/* webpackChunkName: "app/log-browser/files" */'./log-browser/files'),
    'app/login/recovery-password': () => import(/* webpackChunkName: "app/login/recovery-password" */'./login/recovery-password'),
    'app/newsletter-subscription': () => import(/* webpackChunkName: "app/newsletter-subscription" */'./newsletter-subscription'),
    'app/progress-dialog': () => import(/* webpackChunkName: "app/progress-dialog" */'./progress-dialog'),
    'app/profile/announcement-settings': () => import(/* webpackChunkName: "app/profile/announcement-settings" */'./profile/announcement-settings'),
    'app/pum': () => import(/* webpackChunkName: "app/pum" */'./pum'),
    'app/server-mail-antivirus-promo': () => import(/* webpackChunkName: "app/server-mail-antivirus-promo" */'./server-mail-antivirus-promo'),
    'app/show-output-popup': () => import(/* webpackChunkName: "app/show-output-popup" */'./show-output-popup'),
    'app/task-progress-bar': () => import(/* webpackChunkName: "app/task-progress-bar" */'./task-progress-bar'),
    'app/user/login-string': () => import(/* webpackChunkName: "app/user/login-string" */'./user/login-string'),
    'app/change-customers-owner': () => import(/* webpackChunkName: "app/change-customers-owner" */'./change-customers-owner'),
    'app/convert-customers-to-resellers': () => import(/* webpackChunkName: "app/convert-customers-to-resellers" */'./convert-customers-to-resellers'),
    'app/convert-resellers-to-customers': () => import(/* webpackChunkName: "app/convert-resellers-to-customers" */'./convert-resellers-to-customers'),
    'app/service-sidebar': () => import(/* webpackChunkName: "app/service-sidebar" */'components/sidebars/ServiceSidebar'),
    'app/ssl-certificate/list': () => import(/* webpackChunkName: "app/ssl-certificate/list" */'./ssl-certificate/list'),
    'app/app/progress': () => import(/* webpackChunkName: "app/app/progress" */'./app/progress'),
    'app/app/installed': () => import(/* webpackChunkName: "app/app/installed" */'./app/installed'),
    'app/app/apscatalog': () => import(/* webpackChunkName: "app/app/apscatalog" */'./app/apscatalog'),
    'app/web-app-firewall/submit-handler': () => import(/* webpackChunkName: "app/web-app-firewall/submit-handler" */'./web-app-firewall/submit-handler'),
};

export default async (path, callback) => {
    if (!entries[path]) {
        throw new Error(`Unknown required module ${path}.`);
    }

    try {
        const { default: module } = await entries[path]();
        callback?.(module);
        return module;
    } catch (error) {
    // eslint-disable-next-line no-console
        console.error(error);
        return new Promise(() => {});
    }
};
